<template>
  <van-field
    v-model="result"
    is-link
    readonly
    name="picker"
    :label="label"
    placeholder="请选择"
    @click="showPicker = true"
  />
  <van-popup v-model:show="showPicker" position="bottom">
    <van-picker
      :columns="columns"
      @confirm="onConfirm"
      @cancel="showPicker = false"
    />
  </van-popup>
</template>

<script>
import { ref } from 'vue'

export default {
  emits: ['onConfirm'],
  props: {
    label: {
      type: String,
      default: '',
    },
    columns: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  setup(props, { emit }) {
    const result = ref('')
    const showPicker = ref(false)

    const onConfirm = (value) => {
      result.value = value
      showPicker.value = false
      emit('onConfirm', value)
    }

    return {
      result,
      onConfirm,
      showPicker,
    }
  },
}
</script>

<style lang="scss" scoped></style>
