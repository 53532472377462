<template>
  <Tab :list="tabList" :current="2" />
  <div class="flex-1 flex-col fff">
    <Select label="请选择检测室" @onConfirm="onConfirm1" :columns="columns1" />
    <Select label="请选择通道" @onConfirm="onConfirm2" :columns="columns2" />
    <div class="flex-1" v-if="url">
      <iframe class="iframe" :src="url" allowfullscreen> </iframe>
    </div>
  </div>
</template>

<script>
import { supervisionTabList } from '@/utils/tab'
import { reactive, ref } from '@vue/reactivity'
import Tab from '@/components/tab/index.vue'
import Select from './components/select.vue'

export default {
  components: {
    Tab,
    Select,
  },
  setup() {
    const tabList = reactive(supervisionTabList)

    const columns1 = ['杭州', '宁波', '温州', '嘉兴', '湖州']
    const columns2 = ['滨江', '萧山', '余杭', '西湖', '临平']

    const onConfirm1 = (val) => {
      console.log(val)
    }

    const url = ref('')

    const onConfirm2 = (val) => {
      console.log(val)
      url.value =
        'https://open.ys7.com/ezopen/h5/iframe_se?url=ezopen://JXKEWC@open.ys7.com/E42192893/1.live&autoplay=0&audio=1&accessToken=at.6klt252z6z8a09a7dcv15bvl3i6n5ggd-8abszlm9b8-0ntgh6t-5etm69hqp&templete=2'
    }

    return {
      tabList,
      columns1,
      columns2,
      onConfirm1,
      onConfirm2,
      url,
    }
  },
}
</script>

<style lang="less" scoped>
.iframe {
  display: block;
  border: 0;
  width: 100%;
  height: 100%;
}
</style>
